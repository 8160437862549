<template>
  <div class="view--configure-time">

    <div class="dashboard-panels">
    <div class="dashboard-panels--tabs mdtec-card no-padding">
        <v-tabs v-if="tabsLoaded" centered grow background-color="white" color="primary" light persistent>
          <v-tabs-slider></v-tabs-slider>
          <v-tab href="#tab-1" @click="setTabSelected(1)" v-if="workcenter && workcenter.availableSchedule">
            <v-icon class="mr-3">mdi-home</v-icon>
            <span v-if="this.tabSelected===1" >Inicio</span>
          </v-tab>
          <v-tab href="#tab-3" @click="setTabSelected(3)">
            <v-icon class="mr-3">mdi-clock-outline</v-icon>
            <span v-if="this.tabSelected===3">Fichajes</span>
          </v-tab>
          <v-tab href="#tab-2" @click="setTabSelected(2)">
            <v-icon class="mr-3">mdi-alert-circle</v-icon>
            <span v-if="this.tabSelected===2" class="pr-2">Alertas</span>
            <span v-if="this.tabSelected!=2 && totalIncidencesLoaded">{{"(" + totalIncidences + ")" }}</span>
          </v-tab>
        </v-tabs>
      </div>
    </div>
    <div class="left-side">
      <CreateClockEntry v-if="user && user.worker && worker && workcenter&& (tabSelected===1 && workcenter.availableSchedule || tabSelected===3 && !workcenter.availableSchedule)"
      :isAdmin="false" :workerId="user.worker" :worker="worker" @refreshClockEntries="refreshClockEntries"></CreateClockEntry>
      <section-header v-if="workcenter && workcenter.availableSchedule"
       v-show="tabSelected===1" first="O selecciona la fecha, elige el turno" second="y añade tus códigos" />
      <section-header v-show="tabSelected===2" first="A continuación," second="las incidencias abiertas" />
      <div class="row-month-selection" v-show="tabSelected===3">
        <v-btn @click="substractMonthSelected()" icon :dark="$getPrimaryButton('dark')" depressed :rounded="$getPrimaryButton('rounded')"
        :outlined="true" :color="$getPrimaryButton('color')" class="mr-3">
        <v-icon>mdi-arrow-left-bold</v-icon>
      </v-btn>
        <section-header  first="Lista de" :second="'fichajes del mes de ' + getMonthName(this.selectedMonth + 1)" />
        <v-btn @click="addMonthSelected()" icon :dark="$getPrimaryButton('dark')" depressed :rounded="$getPrimaryButton('rounded')"
        :outlined="true" :color="$getPrimaryButton('color')" class="ml-3">
        <v-icon>mdi-arrow-right-bold</v-icon>
      </v-btn>
      </div>

      <WorkerClockEntries v-show="tabSelected===3" ref="workerClockEntries" />
      <v-date-picker v-if="workcenter && workcenter.availableSchedule" v-show="tabSelected===1" v-model="itemData.eventTime" locale="es"
      first-day-of-week="1" :min="minDate" :max="maxDate" :events="functionEvents">
      </v-date-picker>
      <mdtec-panel v-show="tabSelected===2">
        <template v-slot:title> Incidencias </template>
        <template v-slot:content>
          <Incidences v-if="user && user.worker" :isLogged="true" @updateTotal="updateTotalIncidences"/>
        </template>
      </mdtec-panel>
    </div>

    <div class="right-side" v-show="tabSelected===1" v-if="workcenter && workcenter.availableSchedule">
      <div class="panel-loader" v-if="loading">
        <v-progress-circular indeterminate :color="$getColor('vuetifyColor1')"></v-progress-circular>
      </div>

      <div class="check-buttons" v-show="!loading">
        <div class="option-selector--container field-group">
          <label class="option-selector--label field-group--label" for=""
            >Tipo de turno <span class='blocked-text' v-if="isBlocked">(Día bloqueado)</span></label
          >
          <v-tooltip bottom v-if="!isBlocked">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  depressed
                  color="primary"
                  class="mb-2 ml-2"
                  dark
                  x-large
                  icon
                  @click="selectBlank()"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-refresh-circle</v-icon>
                </v-btn>
              </template>
              <span>Reiniciar día</span>
            </v-tooltip>
          <div class="check-buttons--list">
            <check-selector v-show="!loading" title="Turno de mañana" subtitle=""
              ref="morningSelector" v-on:click.native="selectMorning" color="blue"
              :disabled="isBlocked" />
            <check-selector v-show="!loading" title="Turno de tarde" subtitle="" ref="eveningSelector"
              v-on:click.native="selectEvening" color="yellow" :disabled="isBlocked" />
            <check-selector v-show="!loading" title="Turno de noche" subtitle="" ref="nightSelector"
              v-on:click.native="selectNight" color="red" :disabled="isBlocked" />
            <check-selector v-show="!loading" title="Otro" subtitle="" ref="otherSelector"
              v-on:click.native="selectOther" color="purple" :disabled="isBlocked" />
            <check-selector v-show="!loading" title="Ausencia/Baja" subtitle="" ref="absenceSelector"
              v-on:click.native="selectAbsence" color="green" :disabled="isBlocked" />
              <check-selector v-show="!loading" title="Guardia" subtitle="" ref="guardSelector"
              v-on:click.native="selectGuard" color="orange" :disabled="isBlocked" />
          </div>
        </div>
      </div>
      <div class="codes-containter">
        <div
          class="option-selector--container field-group"
          v-if="optionsSelected && optionsSelected.codes.length > 0 && adminCodeListAvailable"
        >
          <label class="option-selector--label field-group--label" for=""
            >Códigos de Administración <span class='blocked-text' v-if="isBlocked">(Día bloqueado)</span></label
          >
          <div class="field-grid">
            <selection-card v-for="item in optionsSelected.codes"
              v-show="item.autoVariable || item.admin" :key="item.id" :name="item.name"
              :class="item.autoVariable ? 'swiper-slide customGreen' : 'swiper-slide customPurple'"
              size="large"
              :description="item.code"
              selected
              :disableMouse="true"
            />
          </div>
        </div>
        <div
          class="option-selector--container field-group"
          v-if="optionsSelected && optionsSelected.codes.length > 0 && codeListAvailable"
        >
          <label class="option-selector--label field-group--label" for=""
            >Tu lista de códigos <span class='blocked-text' v-if="isBlocked">(Día bloqueado)</span></label
          >
          <div class="field-grid">
            <selection-card
              v-for="item in optionsSelected.codes"
              v-show="!item.extraHour && !item.autoVariable && !item.admin"
              :key="item.id"
              :name="item.name"
              :class="item.autoVariable ? 'swiper-slide customGreen' : 'swiper-slide customRed'"
              size="large"
              :description="item.code"
              v-on:click.native="setValue(item)"
              selected
              :disable-mouse="isBlocked"
            />
          </div>
        </div>
        <div class="option-selector--container field-group"
            v-if="optionsSelected && optionsSelected.codes && optionsSelectedWithExtraHours.codes.length > 0">
            <label class="option-selector--label field-group--label" for="">Códigos de unidades</label>
            <div class="field-grid dir-column">
              <div class="field-grid--row" v-for="(item, key) in optionsSelectedWithExtraHours.codes" :key="item.id">
                <selection-card :key="item.id" :name="item.name" class="swiper-slide customRed"
                  size="large" :description="item.code" v-on:click.native="setValue(item)" selected :disable-mouse="isBlocked"/>
                <v-text-field v-model="optionsSelected.extraHours[key]" label="Nº de unidades"
                  type="number" min="0" max="24" step="0.5" outlined hide-details :disabled="isBlocked"></v-text-field>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn small  :dark="$getPrimaryButton('dark')"
                      :rounded="$getPrimaryButton('rounded')" :outlined="$getPrimaryButton('outlined')"
                      :color="$getPrimaryButton('color')" v-bind="attrs" v-on="on" v-if="!isBlocked">
                      <v-icon left small>check_circle</v-icon> GUARDAR
                    </v-btn>
                  </template>
                  <span>Guardar unidades</span>
                </v-tooltip>
              </div>
            </div>
          </div>
        <div
          class="option-selector--container field-group"
          v-if="options && optionsRecommended.length > 0 && !isBlocked"
        >
          <label class="option-selector--label field-group--label" for=""
            >Códigos sugeridos</label
          >
          <div class="field-grid">
            <selection-card v-for="item in optionsRecommended" :key="item.id" :name="item.name"
              class="swiper-slide" size="large" :description="item.code"
              v-on:click.native="setValue(item)" :selected="checkValue(item.id)"
              :disable-mouse="isBlocked || updatingCodes" />
          </div>
        </div>
        <div class="option-selector--container field-group"
          v-if="optionsExtra.length > 0 && !isBlocked">
          <label class="option-selector--label field-group--label" for="">Añade más códigos</label>
          <div class="field-grid">
            <selection-card v-for="item in optionsExtra" :key="item.id" :name="item.name"
              class="swiper-slide" size="large" :description="item.code"
              v-on:click.native="setValue(item)" :selected="checkValue(item.id)"
              :disable-mouse="isBlocked || updatingCodes" />
          </div>
        </div>
      </div>
    </div>
    <div class="right-side mt-4" v-show="tabSelected===3">
      <section-header first="Selecciona el mes" second="y descarga los datos" />
      <v-btn :dark="$getPrimaryButton('dark')" depressed :rounded="$getPrimaryButton('rounded')"
        :outlined="true" :color="$getPrimaryButton('color')" large
        @click="downloadClockEntries()" class="my-2 mr-2" >
        <v-icon left>mdi-download</v-icon>Descargar
      </v-btn>
      <CreateClockEntryManual  v-if="user && user.worker && worker && workcenter"
      :isAdmin="false" :workerId="user.worker" :worker="worker" @refreshClockEntries="refreshClockEntries"></CreateClockEntryManual >
    </div>
  </div>
</template>

<script>
import authService from '@/services/auth.service';

import SectionHeader from '@/components/Headers/SectionHeader.vue';
import CheckSelector from '@/components/Buttons/CheckSelector.vue';
import SelectionCard from '@/components/Cards/CodeCard.vue';
import MdtecPanel from '@/components/Cards/MdtecPanel.vue';
import Incidences from '@/views/_FATDA/components/Incidences.vue';

import CreateClockEntry from '@/views/_FATDA/components/CreateClockEntry.vue';
import CreateClockEntryManual from '@/views/_FATDA/components/CreateClockEntryManual.vue';

import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

import ConfigureTime from '@/services/configure-time.service';
import scheduleService from '@/services/schedule.service';
import clockEntryService from '@/services/clockentry.service';
import loggedUserService from '@/services/logged-user.service';

import WorkerClockEntries from '@/views/_FATDA/components/WorkerClockEntries.vue';

export default {
  name: 'configure-time',
  mixins: [validationMixin],
  components: {
    SectionHeader,
    CheckSelector,
    SelectionCard,
    MdtecPanel,
    Incidences,
    CreateClockEntry,
    CreateClockEntryManual,
    WorkerClockEntries,
  },
  data: () => ({
    user: null,
    itemData: {
      type: null,
      eventTime: null,
    },
    // minDate = first day of current month
    minDate: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 2).toISOString(),
    // maxDate = last day of the month
    maxDate: new Date(new Date().getFullYear(), new Date().getMonth() + 2, 1).toISOString(),

    // selectedMonth
    selectedMonth: new Date().getMonth(),

    modal: false,
    loading: false,
    updatingCodes: false,

    options: null,
    selectedDate: null,
    worker: null,
    workcenter: null,
    n2current: [],
    tabSelected: 1,
    tabsLoaded: false,
    totalIncidences: null,
    totalIncidencesLoaded: false,

    clockEntriesCurrent: [],

  }),
  mounted() {
    this.user = authService.getDecodedJwtToken(this.$store.state.auth.user);
    if (this.user.worker) {
      this.getWorkCenter();
      this.getWorker();
      this.getSchedule();
    } else {
      this.$router.push({ name: 'Home' });
    }
  },
  computed: {
    isBlocked: {
      get() {
        const tempSelected = this.n2current[
          this.n2current.findIndex((item) => item.date === this.selectedDate)
        ];
        if (tempSelected) {
          return tempSelected.blocked;
        }
        return null;
      },
      set() {
        const tempSelected = this.n2current[
          this.n2current.findIndex((item) => item.date === this.selectedDate)
        ];
        if (tempSelected) {
          tempSelected.blocked = !tempSelected.blocked;
        }
      },
    },
    optionsSelected() {
      const tempSelected = this.n2current[this.n2current.findIndex((item) => item.date === this.selectedDate)];
      // order by code.id
      if (tempSelected) {
        tempSelected.codes.sort((a, b) => a.id - b.id);
      }
      return tempSelected;
    },
    optionsSelectedWithExtraHours() {
      if (!this.optionsSelected) {
        return null;
      }
      // create a copy of optionsSelected to avoid modifying the original object
      const optionsSelectedCopy = JSON.parse(JSON.stringify(this.optionsSelected));
      // return items in optionsSelected with extraHours equal to true
      optionsSelectedCopy.codes = optionsSelectedCopy.codes.filter((item) => item.extraHour);
      optionsSelectedCopy.codes.sort((a, b) => a.id - b.id);
      return optionsSelectedCopy;
    },
    optionsRecommended() {
      let newOptions = [];
      if (!this.optionsSelected || !this.options) {
        return newOptions;
      }
      if (this.optionsSelected.type === 'ABSENCE') {
        newOptions = this.options;
        return newOptions;
      }
      if (this.optionsSelected.type === 'NIGHT') {
        newOptions = this.options.filter((item) => item.type === 'all' || item.type === 'night');
      } else if (this.optionsSelected.type === 'EVENING' || this.optionsSelected.type === 'MORNING') {
        newOptions = this.options.filter((item) => item.type === 'all' || item.type === 'morning/evening');
      } else if (this.optionsSelected.type === 'OTHER') {
        // newOptions = this.options.filter((item) => item.extraHour === false);
        newOptions = this.options.filter((item) => item.code === '2007' || item.code === '2095' || item.code === '2002');
      } else if (this.optionsSelected.type === 'GUARD') {
        // newOptions = this.options.filter((item) => item.extraHour === false);
        newOptions = this.options.filter((item) => item.code === '2007' || item.code === '2095' || item.code === '2002'
        || item.code === '2587' || item.code === '2044' || item.code === '2541' || item.code === '2387');
      }
      const isWeekend = ConfigureTime.isWeekend(this.itemData.eventTime);
      // if isWeekend true, exclude all items with weekend: false
      if (isWeekend) {
        newOptions = newOptions.filter((item) => item.weekend !== '0');
      } else {
        newOptions = newOptions.filter((item) => item.weekend !== '1');
      }

      // exclude items with autoVariation: true or admin:true
      newOptions = newOptions.filter((item) => item.autoVariation !== true && item.admin !== true);
      // newOptions = newOptions.filter((item) => item.autoVariable !== true);

      return newOptions;
    },
    optionsExtra() {
      const newOptions = [];
      if (!this.optionsSelected || !this.options) {
        return newOptions;
      }
      this.options.forEach((item) => {
        if (this.optionsRecommended.indexOf(item) === -1) {
          // if item.autoVariable is true, add it to the list
          if (!item.autoVariable && !item.admin) {
            newOptions.push(item);
          }
        }
      });
      // if optionsSelected.type is other, remove all items with extraHour: true
      if (this.optionsSelected.type === 'OTHER') {
        // return newOptions.filter((item) => item.extraHour === false);
        return newOptions.filter((item) => item.code === '2007' || item.code === '2095' || item.code === '2002');
      }

      if (this.optionsSelected.type === 'GUARD') {
        // return newOptions.filter((item) => item.extraHour === false);
        return newOptions.filter((item) => item.code === '2007' || item.code === '2095' || item.code === '2002'
        || item.code === '2587' || item.code === '2044' || item.code === '2541' || item.code === '2387');
      }

      // exclude items with autoVariation: true
      return newOptions;
    },
    codeListAvailable() {
      // if optionsSelected is null or length is 0, return false
      if (!this.optionsSelected || this.optionsSelected.codes.length === 0) {
        return false;
      }
      // if optionsSelected is not null and length is greater than 0, if there is at least one item with !item.extraHour && !item.autoVariable && !item.admin
      // return true
      return this.optionsSelected.codes.some((item) => !item.extraHour && !item.autoVariable && !item.admin);
    },
    adminCodeListAvailable() {
      // if optionsSelected is null or length is 0, return false
      if (!this.optionsSelected || this.optionsSelected.codes.length === 0) {
        return false;
      }
      return this.optionsSelected.codes.some((item) => item.autoVariable || item.admin);
    },
  },

  watch: {
    // watch changes on itemData.eventTime
    'itemData.eventTime': {
      handler() {
        this.getCurrentN2();
      },
      deep: true,
    },
    // watch changes on itemData.type
    'itemData.type': {
      handler() {
        this.updateData();
      },
      deep: true,
    },
    n2current: {
      handler() {
        this.updateCodes();
      },
      deep: true,
    },
    selectedMonth: {
      handler() {
        this.updateClockEntries();
      },
      deep: true,
    },
  },
  validations() {
    const valObj = {
      itemData: {
        type: { required },
        eventTime: { required },
        totalOnSelectedDate: {
          // check if totalOnSelectedDate is less than 10
          lessThan: (val) => val < 6,
        },
      },
    };
    return valObj;
  },
  methods: {

    getWorker() {
      loggedUserService.getWorker().then((response) => {
        this.worker = response;
      });
    },

    getWorkCenter() {
      loggedUserService.getWorkCenter().then((response) => {
        this.workcenter = response;
        if (!this.workcenter.availableSchedule) {
          this.tabSelected = 3;
        } else {
          this.tabSelected = 1;
        }
        this.tabsLoaded = true;
      });
    },

    getSchedule() {
      this.loading = true;
      const dateFrom = new Date(this.minDate).toISOString().split('T')[0];
      const dateTo = new Date(this.maxDate).toISOString().split('T')[0];
      scheduleService.getDaysInRange(this.user.worker, dateFrom, dateTo).then((response) => {
        this.n2current = response;
        this.loading = false;
      });
      this.refreshClockEntries();
    },

    refreshClockEntries() {
      const dateRange = clockEntryService.getDateTimeRange(this.selectedMonth, 'MM');
      clockEntryService.getClockEntriesInRange(this.user.worker, dateRange.dateFrom, dateRange.dateTo).then((response) => {
        this.clockEntriesCurrent = response;
        this.updateRangeClockEntries();
      });
    },

    updateClockEntries() {
      const dateRange = clockEntryService.getDateTimeRange(this.selectedMonth, 'MM');
      clockEntryService.getClockEntriesInRange(this.user.worker, dateRange.dateFrom, dateRange.dateTo).then((response) => {
        this.clockEntriesCurrent = response;
        this.updateRangeClockEntries();
      });
    },

    functionEvents(date) {
      const index = this.n2current.findIndex((item) => item.date === date);
      if (index !== -1) {
        if (this.n2current[index].type === 'ABSENCE') {
          return ['#03fc73'];
        } if (this.n2current[index].type === 'MORNING') {
          return ['#00B0FF'];
        } if (this.n2current[index].type === 'EVENING') {
          return ['#FFEB3B'];
        } if (this.n2current[index].type === 'NIGHT') {
          return ['#FF4081'];
        } if (this.n2current[index].type === 'OTHER') {
          return ['#BD45D1'];
        } if (this.n2current[index].type === 'GUARD') {
          return ['#BD45D1'];
        }
        return [];
      }
      return [];
    },

    getCurrentN2() {
      this.selectedDate = this.itemData.eventTime;
      this.type = null;
      this.resetData();
      this.checkAvailability();
      this.n2 = this.n2current.filter((item) => item.date === this.itemData.eventTime);
      if (this.n2.length > 0) {
        if (this.optionsSelected && this.optionsSelected.type) {
          this.selectData(this.optionsSelected.type);
        }
      } else {
        // add new item to n2current
        this.loading = true;
        scheduleService.addDayToWorkerSchedule(
          this.user.worker,
          {
            date: this.itemData.eventTime,
            type: null,
            codes: [],
          },
        ).then(() => {
          this.getSchedule();
        });
      }
    },
    getMonthName(numeroMes) {
      // Verificar si el número de mes está dentro del rango válido (1 a 12)
      if (numeroMes >= 1 && numeroMes <= 12) {
      // Crear una instancia de Date con el año actual y el número de mes proporcionado
        const fecha = new Date(new Date().getFullYear(), numeroMes - 1, 1); // Restar 1 al número de mes (0-indexed)
        // Obtener el nombre del mes en español utilizando toLocaleString()
        const nombreMes = fecha.toLocaleString('es-ES', { month: 'long' });
        return nombreMes.charAt(0).toUpperCase() + nombreMes.slice(1); // Capitalizar la primera letra del nombre del mes
      }
      return 'Mes inválido'; // Manejar casos fuera del rango
    },

    addMonthSelected() {
      this.selectedMonth += 1;
    },
    substractMonthSelected() {
      this.selectedMonth -= 1;
    },

    updateCodes() {
      if (!this.loading && !this.updatingCodes && this.optionsSelected && this.optionsSelected.id && !this.isBlocked) {
        this.updatingCodes = true;
        scheduleService.updateDay(this.optionsSelected.id, this.optionsSelected).then(() => {
          this.updatingCodes = false;
        });
      }
    },
    updateRangeClockEntries() {
      const eventsCl = [];
      // items foreach
      this.clockEntriesCurrent.forEach((item) => {
        eventsCl.push({
          id: item.id,
          blocked: false,
          start: new Date(item.clockInTime),
          end: new Date(item.clockInTime),
          date: item.clockInTime,
          unixClockInTime: item.unixClockInTime,
          sourceType: item.type,
          manualEntry: item.manualEntry,
          adminEntry: item.adminEntry,
          time: clockEntryService.unixTimeToDateTime(item.unixClockInTime),
          timeLocale: clockEntryService.unixTimeToLocaleDateTime(item.unixClockInTime),
          type: item.type ? this.$getclockEntryTypeValues(item.type).label : 'N/A',
          color: item.type ? this.$getclockEntryTypeValues(item.type).color : 'grey',
          timed: false,
          icon: item.type ? this.$getclockEntryTypeValues(item.type).icon : 'mdi-clock',
          workerId: item.worker.id,
          workerName: item.worker.name,
          workerLastName: item.worker.lastName1,
          workerEmail: item.worker.email,
        });
      });
      this.eventsClock = eventsCl;
      this.$refs.workerClockEntries.updateSchedule(eventsCl, this.worker);
    },

    setValue(item) {
      // check if item is already selected
      if (this.checkValue(item.id)) {
        if (item.extraHour) {
          this.updateRemoveOptionsSelectedExtraHours(item);
        }
        // remove item from optionsSelected
        this.optionsSelected.codes.splice(
          this.optionsSelected.codes.findIndex((i) => i.id === item.id),
          1,
        );
        // add item to options
        this.options.push(item);
      } else {
        // add item to optionsSelected
        this.optionsSelected.codes.push(item);
        // remove item from options
        this.options = this.options.filter((i) => i.id !== item.id);
        // check if item is extrahours
        if (item.extraHour) {
          this.updateOptionsSelectedExtraHours(item);
        }
      }
    },
    updateOptionsSelectedExtraHours(item) {
      // check if itemSelectedWithExtraHours has already items
      if (this.optionsSelectedWithExtraHours.codes.length > 0) {
        // optionsSelectedWithExtraHours is an array ordered by id
        // check the position of the new item
        const index = this.optionsSelectedWithExtraHours.codes.findIndex((i) => i.id === item.id);
        // add new item to the optionsSelected.extraHours[key]
        if (index === -1) {
          // add new item to the end of the array with value 0
          this.optionsSelected.extraHours.push(0);
        } else {
          // add new item to the position of the array with value 0
          this.optionsSelected.extraHours.splice(index, 0, 0);
        }
      }
    },
    updateRemoveOptionsSelectedExtraHours(item) {
      // check if itemSelectedWithExtraHours has already items
      if (this.optionsSelectedWithExtraHours.codes.length > 0) {
        // optionsSelectedWithExtraHours is an array ordered by id
        // check the position of the item
        const index = this.optionsSelectedWithExtraHours.codes.findIndex((i) => i.id === item.id);
        // remove item from the optionsSelected.extraHours[key]
        if (index !== -1) {
          this.optionsSelected.extraHours.splice(index, 1);
        }
      }
    },
    checkValue(id) {
      // check if item is already selected
      return !!(this.optionsSelected.codes.find((item) => item.id === id));
    },

    updateData() {
      if (this.itemData && this.itemData.type) {
        this.optionsSelected.type = this.itemData.type;
      }
      if (this.itemData.type === 'ABSENCE') {
        this.allOptions = ConfigureTime.getAbsencesOptionsForUser(this.worker.n2Codes);
        this.optionsSelected.codes = this.optionsSelected.codes.filter((item) => this.allOptions.find((i) => i.id === item.id));
        // filter options to remove already selected items
        this.options = this.allOptions.filter((item) => !this.checkValue(item.id));
      } else if (this.itemData.type === 'MORNING' || this.itemData.type === 'EVENING' || this.itemData.type === 'NIGHT' || this.itemData.type === 'OTHER') {
        this.allOptions = ConfigureTime.getVariablesOptionsForUser(this.worker.n2Codes);
        this.optionsSelected.codes = this.optionsSelected.codes.filter((item) => this.allOptions.find((i) => i.id === item.id));
        // filter options to remove already selected items
        this.options = this.allOptions.filter((item) => !this.checkValue(item.id));
      } else {
        this.allOptions = [];
      }
    },
    selectData(type) {
      if (type === 'MORNING') {
        this.selectMorning();
      } else if (type === 'EVENING') {
        this.selectEvening();
      } else if (type === 'ABSENCE') {
        this.selectAbsence();
      } else if (type === 'NIGHT') {
        this.selectNight();
      } else if (type === 'OTHER') {
        this.selectOther();
      } else if (type === 'GUARD') {
        this.selectGuard();
      }
    },
    // check availability
    checkAvailability() {
      this.loading = true;
      this.$refs.morningSelector.updateEnabled(true);
      this.$refs.eveningSelector.updateEnabled(true);
      this.$refs.nightSelector.updateEnabled(true);
      this.$refs.absenceSelector.updateEnabled(true);
      this.$refs.otherSelector.updateEnabled(true);

      this.$refs.guardSelector.updateEnabled(true);
      this.loading = false;
    },

    resetData() {
      this.options = null;
      this.itemData.type = null;
      this.$refs.morningSelector.updateSelected(false);
      this.$refs.eveningSelector.updateSelected(false);
      this.$refs.nightSelector.updateSelected(false);
      this.$refs.absenceSelector.updateSelected(false);
      this.$refs.morningSelector.updateEnabled(false);
      this.$refs.eveningSelector.updateEnabled(false);
      this.$refs.nightSelector.updateEnabled(false);
      this.$refs.absenceSelector.updateEnabled(false);
      this.$refs.otherSelector.updateEnabled(false);

      this.$refs.guardSelector.updateSelected(false);
      this.$refs.guardSelector.updateEnabled(false);
    },

    selectMorning() {
      this.itemData.type = 'MORNING';
      this.$refs.morningSelector.updateSelected(true);
      this.$refs.eveningSelector.updateSelected(false);
      this.$refs.nightSelector.updateSelected(false);
      this.$refs.absenceSelector.updateSelected(false);
      this.$refs.otherSelector.updateSelected(false);

      this.$refs.guardSelector.updateSelected(false);
    },
    selectEvening() {
      this.itemData.type = 'EVENING';
      this.$refs.eveningSelector.updateSelected(true);
      this.$refs.morningSelector.updateSelected(false);
      this.$refs.nightSelector.updateSelected(false);
      this.$refs.absenceSelector.updateSelected(false);
      this.$refs.otherSelector.updateSelected(false);

      this.$refs.guardSelector.updateSelected(false);
    },
    selectNight() {
      this.itemData.type = 'NIGHT';
      this.$refs.nightSelector.updateSelected(true);
      this.$refs.eveningSelector.updateSelected(false);
      this.$refs.morningSelector.updateSelected(false);
      this.$refs.absenceSelector.updateSelected(false);
      this.$refs.otherSelector.updateSelected(false);

      this.$refs.guardSelector.updateSelected(false);
    },
    selectAbsence() {
      this.itemData.type = 'ABSENCE';
      this.$refs.eveningSelector.updateSelected(false);
      this.$refs.morningSelector.updateSelected(false);
      this.$refs.nightSelector.updateSelected(false);
      this.$refs.absenceSelector.updateSelected(true);
      this.$refs.otherSelector.updateSelected(false);

      this.$refs.guardSelector.updateSelected(false);
    },
    selectOther() {
      this.itemData.type = 'OTHER';
      this.$refs.eveningSelector.updateSelected(false);
      this.$refs.morningSelector.updateSelected(false);
      this.$refs.nightSelector.updateSelected(false);
      this.$refs.absenceSelector.updateSelected(false);
      this.$refs.otherSelector.updateSelected(true);

      this.$refs.guardSelector.updateSelected(false);
    },
    selectGuard() {
      this.itemData.type = 'GUARD';
      this.$refs.eveningSelector.updateSelected(false);
      this.$refs.morningSelector.updateSelected(false);
      this.$refs.nightSelector.updateSelected(false);
      this.$refs.absenceSelector.updateSelected(false);
      this.$refs.otherSelector.updateSelected(false);

      this.$refs.guardSelector.updateSelected(true);
    },
    selectBlank() {
      this.itemData.type = 'BLANK';
      this.$refs.eveningSelector.updateSelected(false);
      this.$refs.morningSelector.updateSelected(false);
      this.$refs.nightSelector.updateSelected(false);
      this.$refs.absenceSelector.updateSelected(false);
      this.$refs.otherSelector.updateSelected(false);

      this.$refs.guardSelector.updateSelected(false);

      this.allOptions = [];
      this.options = null;
      this.optionsSelected.codes = [];
    },
    setTabSelected(number) {
      this.tabSelected = number;
    },
    updateTotalIncidences(total) {
      this.totalIncidences = total;
      this.totalIncidencesLoaded = true;
    },
    downloadClockEntries() {
      clockEntryService.downloadClockEntries(this.eventsClock, this.worker, false);
    },
  },

};
</script>
<style lang="scss" scoped>
.v-picker--date {
  // margin-bottom: calc(var(--base) * 4);
  z-index: 0;
}

// ::v-deep .theme--light.v-btn.v-btn--disabled,
// .theme--light.v-btn.v-btn--disabled .v-btn__loading,
// .theme--light.v-btn.v-btn--disabled .v-icon {
//   background-color: #f1f1f1;
// }
.page-layout {
  height: 100%;
}

/*add selected with vuetifyColor*/
.selected {
  border: 2px solid var(--color1);
}

.codes-container {
  display: flex;
  flex-direction: column;
  gap: calc(var(--base) * 2);
  overflow-x: hidden;
  grid-column: 1 / span 5;
  width: calc(100% + calc(var(--base) * 6));
  margin-left: calc(var(--base) * -3);
}

//redisign
.view--configure-time {
  //height: 100%;
  height: max-content;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: calc(var(--base) * 0.5);

  @media (min-width: 1024px) {
    grid-template-columns: repeat(12, 1fr);
    padding-top: 0;
  }

  .left-side {
    grid-column: 1 / span 6;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: calc(var(--base) * 4);
    gap: calc(var(--base) * 2);

    @media (min-width: 1024px) {
      grid-column: 1 / span 7;
    }
  }

  .right-side {

    grid-column: 1 / span 6;
    height: 100%;

    @media (min-width: 1024px) {
      grid-column: 8 / span 5;
      padding-left: calc(var(--base) * 3);
    }

    .check-buttons {
      display: flex;
      flex-direction: column;

      .check-buttons--list {
        margin-top: calc(var(--base) * 1);
        display: flex;
        flex-wrap: wrap;
        gap: calc(var(--base) * 2);
      }
    }

    .panel-loader {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
<style lang="scss">
.view--configure-time {
  & .v-picker__body.theme--light {
    margin: 0;
    width: 100% !important;
    padding: calc(var(--base) * 1) calc(var(--base) * 2);
  }

  & .v-date-picker-table .v-btn {
    font-size: calc(var(--base) * 2);
  }

  & .v-picker.v-card.v-picker--date.theme--light {
    height: 100%;
    max-height: calc(var(--layout-height) - calc(var(--base) * 32));
    min-height: calc(var(--layout-height) - calc(var(--base) * 34));

    & table {
      height: 100%;
    }

  }

  & .v-picker__body.theme--light {
    &>div {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  & .v-date-picker-table.v-date-picker-table--date.theme--light {
    height: 100%;
  }

  & .accent--text {
    button {
      font-size: calc(var(--base) * 2.5);
      text-transform: capitalize;
    }
  }

  & .v-date-picker-header.theme--light {
    margin-top: calc(var(--base) * 1);
    margin-bottom: calc(var(--base) * 2);
  }

  & .v-date-picker-title {
    color: #161616;
    text-transform: capitalize;
  }

  & .v-btn.v-btn--active.v-btn--rounded.theme--light.accent {
    background-color: #f4796b !important;

    & .v-btn__content {
      color: #161616;
    }
  }
}

.dashboard-panels {
    grid-column: 1 / span 6;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: calc(var(--base)* 2);
    padding: calc(var(--base)* 2) 0;
    @media (min-width: 1024px) {
      grid-column: 1 / span 7;
    }
    padding-bottom: calc(var(--base)* 1);
  }

  .dashboard-panels--tabs {
    margin-bottom: calc(var(--base) * 1);
    min-height: 50px;
  }

  .dashboard-panels--tabs-panel {
    height: 100%;
  }
  .section-header{
    margin-bottom: calc(var(--base)* 1.5)!important;
    margin-top: calc(var(--base)* 1.5) !important;
  }
  .row-month-selection{
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
